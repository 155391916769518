import { GraduationCap, ContactRound, Award } from "lucide-react";
import { DiscordLogo } from "@fefyi/ui/astro/icons/discord-logo";
import { Tile } from "./tile";

export const SubnavMore = () => (
  <>
    <div className="grid gap-2 md:grid-cols-[repeat(1,250px)] lg:grid-cols-[repeat(2,250px)]">
      <Tile
        title="Mentorship"
        subtitle="Skip all the rabbit holes"
        icon={GraduationCap}
        href="/mentorships"
      />
      <Tile
        title="Discord"
        subtitle="Join our community — part of PRO"
        icon={DiscordLogo}
        href="/discord"
      />
      <Tile
        title="About"
        subtitle="Why I know so much? ;)"
        icon={ContactRound}
        href="/about"
      />
      <Tile
        className="md:bg-gradient-to-b md:from-pro/15 md:to-pro/5"
        title="PRO"
        subtitle="Pay once, learn forever"
        icon={Award}
        href="/pro"
      />
    </div>
  </>
);
