import { ArrowRight, ChevronDown, Menu, X } from "lucide-react";
import React, { useState, useEffect, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { SubnavCourses } from "./subnav-courses";
import { SubnavMore } from "./subnav-more";
import { LogoIcon } from "@fefyi/ui/logo";
import { SubnavTuts } from "./subnav-tuts";
import { Tile } from "./tile";

type Props = {
  isPro?: boolean;
  activePath: string;
  children: React.ReactNode;
};

const items = ["videos", "recipes", "courses", "playgrounds", "more"] as const;
type Items = (typeof items)[number];

type ItemProps = {
  children?: React.ReactNode;
  id: Items;
  href?: string;
  title: string;
  activePath?: string;
  hideSubnav?: boolean;
};

const MenuItem = ({
  children,
  href,
  title,
  activePath,
  hideSubnav,
}: ItemProps) => {
  const El = useMemo(() => (href ? "a" : "button"), [href]);

  return (
    <li className="group relative max-md:flex max-md:flex-col max-md:border-t max-md:border-white-opaque max-md:px-6 max-md:py-2 max-md:last-of-type:border-b">
      <El
        className={twMerge(
          "relative flex cursor-pointer flex-row items-center leading-[1] outline-none after:absolute after:-bottom-px after:left-2 after:right-2 after:block after:h-[1px] after:bg-white after:transition-[transform,opacity] max-md:py-4 max-md:text-center max-md:after:hidden md:h-5 md:px-3 [&:not(.active)]:after:translate-y-2 [&:not(.active)]:after:opacity-0 group-focus-within:[&:not(.active)]:after:translate-y-0 group-focus-within:[&:not(.active)]:after:opacity-100 group-hover:[&:not(.active)]:after:translate-y-0 group-hover:[&:not(.active)]:after:opacity-100 [&:not([href])]:after:hidden",
          href && activePath && activePath.startsWith(href) && "active",
        )}
        href={href}
      >
        {title}

        {!href && (
          <ChevronDown className="transition-transform group-focus-within:translate-y-[2px] group-hover:translate-y-[2px] max-md:hidden" />
        )}
      </El>

      {children && (
        <div
          className={twMerge(
            "text-white group-focus-within:pointer-events-auto group-focus-within:z-20 group-hover:z-20 max-md:pb-4 md:pointer-events-none md:absolute md:top-full md:scale-90 md:pt-4",
            hideSubnav !== true &&
              "md:opacity-0 md:group-focus-within:translate-y-0 md:group-focus-within:scale-100 md:group-focus-within:opacity-100 md:group-focus-within:duration-300 md:group-hover:pointer-events-auto md:group-hover:translate-y-0 md:group-hover:scale-100 md:group-hover:opacity-100 md:group-hover:transition-[opacity,transform] md:group-hover:duration-300 lg:left-1/2 lg:-translate-x-1/2 lg:translate-y-2",
            hideSubnav === true && "md:hidden",
          )}
        >
          <div
            className={twMerge(
              "relative z-10 overflow-clip rounded-2xl text-white",
              hideSubnav !== true &&
                "md:bg-stone md:border md:border-white-opaque md:p-2",
            )}
          >
            <div>
              <div className="relative">{children}</div>
            </div>
          </div>
        </div>
      )}
    </li>
  );
};

export const Navigation = ({ children, activePath }: Props) => {
  const [isNavVisible, setIsNavVisible] = useState(false);

  useEffect(() => {
    if (isNavVisible) {
      document.documentElement.classList.add("overflow-hidden");
    } else {
      document.documentElement.classList.remove("overflow-hidden");
    }
  }, [isNavVisible]);

  useEffect(() => {
    const onResizeCloseNav = () => {
      setIsNavVisible(false);
    };

    window.addEventListener("resize", onResizeCloseNav);
    return () => window.removeEventListener("resize", onResizeCloseNav);
  });

  return (
    <>
      <button
        onClick={() => setIsNavVisible(true)}
        className="ml-auto md:hidden"
      >
        <Menu />
        <span className="sr-only">Show navigation</span>
      </button>

      <nav
        className={twMerge(
          "fixed inset-0 z-50 h-[100dvh] w-full bg-black text-xl transition-opacity max-md:overflow-auto md:pointer-events-auto md:relative md:z-0 md:flex md:h-auto md:translate-x-0 md:flex-row md:items-center md:bg-transparent md:text-md md:opacity-100",
          isNavVisible
            ? "translate-x-0 opacity-100 [--fade-delay:200ms] [&_li]:translate-y-2 [&_li]:animate-fadeInUp [&_li]:opacity-0"
            : "pointer-events-none translate-x-[100vw] opacity-0",
        )}
      >
        <button
          onClick={() => setIsNavVisible(false)}
          className="absolute right-4 top-6 z-20 before:absolute before:-inset-2 md:hidden"
        >
          <X />
          <span className="sr-only">Hide navigation</span>
        </button>

        <ul className="flex min-h-full w-full flex-col max-md:pb-8 max-md:pt-6 md:flex-row md:items-center md:justify-center">
          <li className="mb-6 px-6 md:hidden">
            <LogoIcon />
          </li>
          <MenuItem
            id="videos"
            title="Tutorials"
            href="/tutorials"
            activePath={activePath}
          >
            <SubnavTuts />
          </MenuItem>
          <MenuItem
            id="recipes"
            title="Recipes"
            href="/recipes"
            activePath={activePath}
            hideSubnav
          >
            <div className="flex flex-col md:hidden">
              <Tile
                icon={ArrowRight}
                className="mr-auto border border-white-opaque bg-white-opaque px-4 md:hidden [&_svg]:w-4"
                title="View all"
                href="/recipes"
              />
            </div>
          </MenuItem>

          <MenuItem
            id="courses"
            title="Courses"
            href="/courses"
            activePath={activePath}
          >
            <SubnavCourses />
          </MenuItem>

          <MenuItem
            id="playgrounds"
            title="Playgrounds"
            href="/dev"
            activePath={activePath}
          />

          <MenuItem id="more" title="More" activePath={activePath}>
            <SubnavMore />
          </MenuItem>
        </ul>
        <div className="max-md:pb-8 md:min-w-[250px]">
          <ul className="flex flex-col md:flex-row">{children}</ul>
        </div>
      </nav>
    </>
  );
};
