import { Sparkle, useSparkles } from "@fefyi/ui/sparkles";
import {
  useScroll,
  useTransform,
  motion,
  easeInOut,
  useSpring,
} from "motion/react";
import { useRef } from "react";
import { twMerge } from "tailwind-merge";
import type { CollectionEntry } from "astro:content";
import { ChevronRight } from "lucide-react";

type Video = CollectionEntry<"tutorials">;

export const HeroVideo = ({ children }: { children: React.ReactNode }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "start end"],
  });
  const rotateY = useTransform(scrollYProgress, [0, 1], ["0deg", "10deg"]);
  const rotateX = useTransform(scrollYProgress, [0, 1], ["0deg", "25deg"]);
  const rotateZ = useTransform(scrollYProgress, [0, 1], ["0deg", "-5deg"]);
  const scale = useTransform(scrollYProgress, [0, 1], [1, 1.3]);
  const y = useTransform(scrollYProgress, [0, 1], ["-20%", "0%"]);

  return (
    <motion.div
      ref={ref}
      style={
        {
          "--rotate-y": rotateY,
          "--rotate-x": rotateX,
          "--rotate-z": rotateZ,
          "--scale": scale,
          "--y": y,
        } as React.CSSProperties
      }
      className="relative grid aspect-[1/0.58] bg-background max-md:aspect-[1/1.77] md:place-items-start"
    >
      <div className="relative z-0 h-full w-full [transform:rotateY(var(--rotate-y))_rotateX(var(--rotate-x))_rotateZ(var(--rotate-z))_scale(var(--scale))_translateY(var(--y))]">
        <div className="fadeout-bottom bg-background [--fade-distance:100px]">
          {children}
        </div>
      </div>
    </motion.div>
  );
};

export const HeroSparkles = ({ children }: { children: React.ReactNode }) => {
  const sparkles = useSparkles();
  return (
    <span className="relative">
      <span aria-hidden>
        {sparkles.map((sparkle) => (
          <Sparkle {...sparkle} key={sparkle.id} />
        ))}
      </span>
      {children}
    </span>
  );
};

export const VideoScroller = ({ videos: videoList }: { videos: Video[][] }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const springScrollY = useSpring(scrollYProgress, { bounce: 0.1 });

  const x1 = useTransform(springScrollY, [0, 1], ["0%", "5%"], {
    ease: easeInOut,
  });
  const x1Mobile = useTransform(springScrollY, [0, 1], ["0%", "25%"], {
    ease: easeInOut,
  });
  const x2 = useTransform(springScrollY, [0, 1], ["0%", "-7%"], {
    ease: easeInOut,
  });
  const x2Mobile = useTransform(springScrollY, [0, 1], ["0%", "-25%"], {
    ease: easeInOut,
  });
  const x3 = useTransform(springScrollY, [0, 1], ["0%", "8%"], {
    ease: easeInOut,
  });
  const x3Mobile = useTransform(springScrollY, [0, 1], ["0%", "30%"], {
    ease: easeInOut,
  });

  return (
    <div ref={ref} className="py-2">
      {videoList.map((videos, index) => (
        <motion.div
          style={
            {
              "--x": index === 0 ? x1 : index === 1 ? x2 : x3,
              "--x-mobile":
                index === 0 ? x1Mobile : index === 1 ? x2Mobile : x3Mobile,
            } as React.CSSProperties
          }
          className={twMerge(
            "left-1/2 mb-3 grid -translate-x-1/2 grid-cols-[repeat(10,35vw)] items-center gap-3 text-left md:grid-cols-[repeat(10,20vw)]",
            index === 0 && "translate-x-[calc(-50%_+_10vw_+_var(--x))]",
            index === 1 && "translate-x-[calc(-50%_-_10vw_+_var(--x))]",
            index === 2 && "translate-x-[calc(-50%_+_15vw_+_var(--x))]",
            index === 0 &&
              "max-md:translate-x-[calc(-50%_+_10vw_+_var(--x-mobile))]",
            index === 1 &&
              "max-md:translate-x-[calc(-50%_-_10vw_+_var(--x-mobile))]",
            index === 2 &&
              "max-md:translate-x-[calc(-50%_+_15vw_+_var(--x-mobile))]",
          )}
        >
          {videos.map((video) => (
            <a
              href={`/tutorials/${video.slug}`}
              className="group relative rounded-lg before:pointer-events-none before:absolute before:inset-0 before:rounded-lg before:opacity-0 before:ring-4 before:ring-white-opaque-dark before:transition-opacity before:duration-300 hover:before:opacity-100 focus-visible:before:opacity-100"
            >
              <span className="grid h-full w-full overflow-clip rounded-lg *:[grid-area:1/1]">
                <img
                  className="rounded-lg transition-transform duration-500 group-hover:scale-105 group-focus-visible:scale-105"
                  src={`/video-images/${video.data.image}.webp`}
                  alt={`Video cover image of video: ${video.data.title}`}
                />

                <span className="absolute bottom-0 right-0 flex translate-x-4 items-center gap-2 rounded-br-lg rounded-tl-lg bg-black p-3 text-lg opacity-0 backdrop-blur-xl transition-[opacity,transform,background-color] delay-200 duration-300 hover:bg-background-contrast focus-visible:bg-background-contrast group-hover:opacity-100 group-focus-visible:translate-x-0 group-focus-visible:opacity-100 md:group-hover:translate-x-0 md:group-hover:opacity-100">
                  <span aria-hidden>Check it out</span>
                  <span className="sr-only">{video.data.title}</span>
                  <ChevronRight className="h-4 w-4" />
                </span>
              </span>
            </a>
          ))}
        </motion.div>
      ))}
    </div>
  );
};
